@import "variables";
.group {
    margin-bottom: 20px;
    .group-content {
      border: 2px solid $link;
      border-radius: 5px;
      padding: 10px;
      transition: background-color 0.2s linear;
      margin: 10px;
      padding: 0px !important;
      &.selected {
        box-shadow: 0 0 6px $link;
      }
      
      flex: auto;
      background: white;

      &.active {
        border: 4px solid $link;
      }
     .group-header {
        padding: 2px 0;
        position: relative;
        box-shadow: 0 0 6px $link;
        border-radius: 4px;
        cursor: pointer;
        display: flex;
        align-items: center;
        p, input {
          margin: 0;
          text-align: center;
          font-weight: 600;
          line-height: 40px;
          padding: 0;
          border: none;
          svg {
            fill: $link;
            position: absolute;
            right: 6px;
          }
          &:read-only {
            cursor: pointer;
            color: $link;
          }
        }
        .fte-sum-header {
          text-align: left;
          padding: 2px 4px;
          font-weight: 600;
          color: $link;
          margin-right: auto;
        }
        .close {
          color: red;
          position: absolute;
          right: 10px;
          top: 10px;

          &.hidden {
            display: none;
          }
        }
      }
      .cards-wrapper {
        overflow-y: auto;
        overflow-x: hidden;
        position: relative;
        width: 315px;
        min-height: 100px;
        & > div {
            display: block;
            min-height: 100px;
            height: max-content;
            padding: 20px 0;      
        }
      }
    }
  }
  .group-row {
    margin-bottom: 10px;
    .group-content {
      border: 1px solid var(--groupColor);
      border-radius: 3px;
      padding: 5px;
      transition: background-color 0.2s linear;
      margin: 5px;
      padding: 0px !important;
      flex: auto;

      &.active {
        border: 4px solid var(--groupColor);
      }
     .group-header {
        padding: 2px 0;
        position: relative;
        border-radius: 4px;
        p {
          color: var(--groupColor);
          margin: 0;
          text-align: left;
          padding-left: 20px;
          font-weight: 600;
          line-height: 20px;
          svg {
            fill: $link;
            position: absolute;
            right: 6px;
          }
        }
      }
      .cards-wrapper {
        overflow-x: auto;
        overflow-y: hidden;
        position: relative;
        height: 110px; 
        white-space: nowrap;
        & > div {
            display: flex;
            width: max-content;
            min-width: 100%;
        }
      }
    }
  }
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
  }
  
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  }
  