/* Dashboard.css */
@import "variables";

.dashboard {
    display: flex;
    flex-wrap: wrap;
    gap: 20px; /* Adjust the space between cards as needed */
    justify-content: center; /* Centers the cards in the container */
  }
  
  .kpi-card {
    background-color: $background; /* Change to the color of your card background */
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Simple shadow to lift the card off the page */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px;
    width: 300px; /* Set a fixed width or use min-width and max-width for responsive design */
  }
  
  .kpi-icon {
    font-size: 24px; /* Adjust icon size as needed */
    text-align: center;
    margin-bottom: 10px;
  }
  
  .kpi-info {
    text-align: center;
  }
  
  .kpi-value {
    font-size: 24px; /* Adjust according to your design */
    font-weight: bold;
    color: $paragraph; /* Change to match the text color you want */
  }
  
  .kpi-title {
    font-size: 16px; /* Adjust according to your design */
    color: $black; /* Change to match the text color you want */
    margin-top: 5px;
  }
  