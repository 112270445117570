.recommendations-list {
    list-style: none;
    padding: 0;
  }
  
  .recommendation-item {
    margin-bottom: 20px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 8px;
  }
  
  .icon {
    font-size: 24px;
  }
  
  h3 {
    margin-top: 0;
  }