.loader-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  
  .loader-circle {
    display: flex;
    align-items: center;
    justify-content: space-around;
    position: relative;
    animation: rotate linear infinite;
  }
  
  .circle {
    border-radius: 50%;
    position: absolute;
  }
  
  .circle:nth-child(1) {
    animation: move linear infinite;
  }
  
  .circle:nth-child(2) {
    animation: move linear infinite reverse;
  }
  
  .circle:nth-child(3) {
    animation: move 2.5s linear infinite;
  }
  
  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  @keyframes move {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(20px);
    }
  }
  
  .loading-container {
    text-align: center;
  }
  